import * as React from "react";
import { IconProps } from "..";

const Twitter = React.forwardRef<SVGSVGElement, IconProps>(
  ({ title = "twitter", titleId = "twitter", ...props }, svgRef) => {
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
        aria-labelledby={titleId}
        {...props}
      >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
          d="M12 8.25c0-2.062 1.734-3.778 3.797-3.75a3.75 3.75 0 013.394 2.25H22.5l-3.028 3.028A11.99 11.99 0 017.5 21c-3 0-3.75-1.125-3.75-1.125s3-1.125 4.5-3.375c0 0-6-3-4.5-11.25 0 0 3.75 3.75 8.25 4.5v-1.5z"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);
export default Twitter;
